<template>
  <div class="landing">
    <!--intro-->
    <div class="landing-flat">
      <div class="landing__container">
        <div class="landing-intro">
          <h1 v-if="$store.state.lang === 'ja'" class="landing-intro__title">
            <strong v-view class="word-pulse">{{ $t("fineArt") }}</strong
            >{{ $t("homeTitle1") }}
          </h1>
          <h1 v-else class="landing-intro__title">
            {{ $t("homeTitle1") }}
            <strong v-view class="word-pulse">{{ $t("fineArt") }}</strong>
            <!-- {{ $t("from1000") }} -->
          </h1>
          <img
            class="landing-intro__illustration"
            src="../assets/home/main.jpg"
            alt=""
          />
          <router-link
            v-if="!$store.state.user"
            v-view
            class="landing-cta"
            :to="{ params: { lang: $store.state.lang }, name: 'invite' }"
          >
            {{ $t("requestInvite") }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- benefits -->
    <div class="landing-flat">
      <div class="landing__container">
        <div class="landing-secure-block-wrap">
          <h2 class="landing-flat__title">
            {{ $t("benefitsTitle") }}
          </h2>
          <p class="landing-flat__intertitle">
            {{ $t("benefitsIntro") }}
          </p>
          <ul class="landing-secure-block">
            <li class="neo-card landing-secure-block__item">
              <div>
                <strong>
                  {{ $t("benefit1Title") }}
                </strong>
              </div>
              <img class="benefits-image" :src="getImgUrl()" />
              <div>
                {{ $t("benefit1Text") }}
              </div>
            </li>
            <li class="neo-card landing-secure-block__item">
              <div>
                <strong>
                  {{ $t("benefit2Title") }}
                </strong>
              </div>
              <img class="benefits-image" src="../assets/home/socialise.jpg" />
              <div>
                {{ $t("benefit2Text") }}
              </div>
            </li>
            <li class="neo-card landing-secure-block__item">
              <div>
                <strong>
                  {{ $t("benefit3Title") }}
                </strong>
              </div>

              <img class="benefits-image" src="../assets/home/experience.jpg" />
              <div>
                {{ $t("benefit3Text") }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--works-->
    <div class="landing-flat">
      <div class="landing__container">
        <div class="landing-works">
          <h2 class="landing-flat__title">
            {{ $t("featuredWorks") }}
          </h2>
          <ul class="landing-feature-works">
            <li>
              <img
                src="https://images.neoone.art/artwork/7EkTaQM0ZgTHSi5RjqNz/7EkTaQM0ZgTHSi5RjqNz_1.jpg"
              />
            </li>
            <li>
              <img
                src="https://images.neoone.art/artwork/renQ6foR1Yik4K7CkANw/renQ6foR1Yik4K7CkANw_1.jpg"
              />
            </li>
            <li>
              <img
                src="https://images.neoone.art/artwork/tm6Fm3TI0DeLwwC246AO/tm6Fm3TI0DeLwwC246AO_1.jpg"
              />
            </li>
          </ul>
          <router-link
            v-view
            class="landing-cta"
            :to="{ params: { lang: $store.state.lang }, name: 'artwork' }"
          >
            {{ $t("seeMore") }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- invest -->
    <div class="landing-flat">
      <div class="landing__container">
        <div class="landing-secure-block-wrap">
          <h2 class="landing-flat__title">
            {{ $t("investTitle") }}
          </h2>
          <p class="landing-flat__intertitle">
            {{ $t("investIntro") }}
          </p>
          <p class="landing-flat__intertitle">
            {{ $t("investIntro2") }}
          </p>
          <ul class="landing-secure-block">
            <li class="neo-card landing-secure-block__item">
              <div>
                <strong>
                  {{ $t("invest1Title") }}
                </strong>
              </div>
              <img class="benefits-image" :src="getImg('collection')" />
              <div>
                {{ $t("invest1Text") }}
              </div>
            </li>
            <li class="neo-card landing-secure-block__item">
              <div>
                <strong>
                  {{ $t("invest2Title") }}
                </strong>
              </div>
              <img class="benefits-image" src="../assets/home/frames.jpg" />
              <div>
                {{ $t("invest2Text") }}
              </div>
            </li>
            <li class="neo-card landing-secure-block__item">
              <div>
                <strong>
                  {{ $t("invest3Title") }}
                </strong>
              </div>

              <img class="benefits-image" :src="getImg('certificate')" />
              <div>
                {{ $t("invest3Text") }}
              </div>
            </li>
          </ul>
          <router-link
              v-if="!$store.state.user"
              v-view
              class="landing-cta"
              :to="{ params: { lang: $store.state.lang }, name: 'invite' }"
            >
              {{ $t("requestInvite") }}
            </router-link>
        </div>
        <div>
          <p class="references-text">
            {{ $t("investRef1") }} <a class="link-text" :href="$t('investRefLink2')">{{ $t("investRef2") }}</a> {{$t('and')}} <a class="link-text" :href="$t('investRefLink3')">{{ $t("investRef3") }}</a>
          </p>
        </div>
      </div>
    </div>
    <!--how-->
    <div class="landing-flat">
      <div class="landing__container">
        <h2 class="landing-flat__title">
          {{ $t("howItWorks") }}
        </h2>
        <ol class="landing-how">
          <li class="landing-how__item" v-view>
            <div class="landing-how__title">
              {{ $t("homeStep1Title") }}
            </div>
            <figure class="landing-how__figure">
              <img src="../assets/home/undraw_art_museum_-8-or4.svg" />
              <p>
                {{ $t("homeStep1Text") }}
              </p>
            </figure>
          </li>
          <li class="landing-how__item" v-view>
            <div class="landing-how__title">
              {{ $t("homeStep2Title") }}
            </div>
            <figure class="landing-how__figure">
              <img src="../assets/home/undraw_abstract_re_l9xy.svg" />
              <p>
                {{ $t("homeStep2Text") }}
              </p>
            </figure>
          </li>
          <li class="landing-how__item" v-view>
            <div class="landing-how__title">
              {{ $t("homeStep3Title") }}
            </div>
            <figure class="landing-how__figure">
              <img src="../assets/home/undraw_stripe_payments_o-7-aw.svg" />
              <p>
                {{ $t("homeStep3Text") }}
              </p>
            </figure>
          </li>
          <li class="landing-how__item" v-view>
            <div class="landing-how__title">{{ $t("homeStep4Title") }}</div>
            <figure class="landing-how__figure">
              <img src="../assets/home/undraw_work_chat_re_qes4.svg" />
              <p>
                {{ $t("homeStep4Text") }}
              </p>
            </figure>
            
          </li>
        </ol>
      </div>
    </div>
    <!--next-->
    <div class="landing-flat">
      <div class="landing__container">
        <h2 class="landing-flat__title">
          {{ $t("whatIsNext") }}
        </h2>
        <p class="landing-flat__intertitle">
          {{ $t("homeNextTitle") }}
        </p>
      </div>
      <ul class="landing-whats-next">
        <li class="landing-whats-next__item fancy-border-radius-left" v-view>
          <div class="landing-whats-next__title">
            {{ $t("homeNextStep1Title") }}
          </div>
          <div class="landing-whats-next__figure">
            <img src="../assets/home/undraw_wishlist_re_m7tv.svg" />
            <div class="text-center font-weight-bold">
              {{ $t("homeNextStep1Text") }}
            </div>
          </div>
        </li>
        <li class="landing-whats-next__item fancy-border-radius-right" v-view>
          <div class="landing-whats-next__title">
            {{ $t("homeNextStep2Title") }}
          </div>
          <div class="landing-whats-next__figure">
            <img src="../assets/home/undraw_add_to_cart_re_wrdo.svg" />
            <div class="text-right font-weight-bold">
              {{ $t("homeNextStep2Text") }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!--secure-->
    <!-- <div class="landing-flat">
      <div class="landing__container">
        <div class="landing-secure-block-wrap">
          <h2 class="landing-flat__title">
            {{ $t("homeBlockchainTitle") }}
          </h2>
          <p class="landing-flat__intertitle">
            {{ $t("weUse") }}

            <span v-view class="word-pulse">{{ $t("blockchain") }}</span>
            {{ $t("homeBlockchainText") }}
          </p>
          <ul class="landing-secure-block">
            <li class="neo-card landing-secure-block__item">
              <div>
                <strong>
                  {{ $t("blockchainCard1Title") }}
                </strong>
              </div>
              <img src="../assets/home/undraw_ethereum_re_0m68.svg" />
              <div>
                {{ $t("blockchainCard1Text") }}
              </div>
            </li>
            <li class="neo-card landing-secure-block__item">
              <div>
                <strong>
                  {{ $t("blockchainCard2Title") }}
                </strong>
              </div>

              <img src="../assets/home/undraw_personal_file_re_5joy.svg" />
              <div>
                {{ $t("blockchainCard2Text") }}
              </div>
            </li>
            <li class="neo-card landing-secure-block__item">
              <div>
                <strong>
                  {{ $t("blockchainCard3Title") }}
                </strong>
              </div>
              <img src="../assets/home/undraw_world_re_768g.svg" />
              <div>
                {{ $t("blockchainCard3Text") }}
              </div>
            </li>
          </ul>

          <router-link
            v-if="!$store.state.user"
            v-view
            class="landing-cta"
            :to="{ params: { lang: $store.state.lang }, name: 'register' }"
          >
            {{ $t("register") }}
          </router-link>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "home-how",
  methods: {

    getImg(filename) {
      const images = require.context("@/assets/home/", false, /\.png$/);
      // const images = require.context('@/assets/company/', false, /\.jpg$/)
      return images(
        `./${filename}-${this.$store.state.lang}.png`
      );
    },
    getImgUrl() {
      const images = require.context("@/assets/home/", false, /\.jpg$/);
      // const images = require.context('@/assets/company/', false, /\.jpg$/)
      return images(
        "./frame-browser-foujita-" + this.$store.state.lang + ".jpg"
      );
    },
    getCertImgUrl() {
      const images = require.context("@/assets/home/", false, /\.png$/);
      // const images = require.context('@/assets/company/', false, /\.jpg$/)
      return images(
        "./certificate-" + this.$store.state.lang + ".png"
      );
    },
  },
  computed: {
    frameBrowserImage() {
      console.log(this.$t("frameBrowserImage"));
      return this.$t("frameBrowserImage");
    },
  },
};
</script>
<style scoped lang="scss">
.references-text {
  font-style: 6px;
  text-align: center;
  padding-top: 15px;
}
/* unvisited link */
.link-text {
  color: var(--color-primary);
  font-weight: 600;
}

.benefits-image {
  border-radius: 5px;
  border-color: var(--color-primary);
  border-style: solid;
  border-width: thin;
}
.landing {
  background-color: var(--color-white);
  height: 100%;
  width: 100%;
  &__container {
    width: var(--main-width-container);
    margin: auto;
  }
}

.landing-flat {
  padding: 32px 16px;
  &:nth-child(2n) {
    background-color: var(--color-grey-light);
  }
  &__title {
    text-align: center;
    font-weight: 600;
    font-size: var(--font-size-3);
    margin-bottom: 48px;
  }
  &__intertitle {
    text-align: center;
    font-size: var(--font-size-4);
    font-weight: 300;
  }
}
// cta
.landing-cta {
  opacity: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  display: inline-block;
  border-radius: 4px;
  padding: 0.5em;
  font-weight: 900;
  font-size: clamp(1rem, 2.5vw, 2rem);
  color: var(--color-white);
  background-color: var(--color-primary);
  transition-duration: 0.3s;
  transition-property: transform;

  &:before {
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    );

    transition-duration: 0.3s;

    transition-property: opacity;
  }
  &:hover {
    z-index: 1;
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    transform: translate(-50%, -5px);
    &:before {
      opacity: 1;
      transform: translateY(5px);
    }
  }

  &--animable {
    opacity: 0;
  }
  &.view-in--full {
    animation: opacityAnimation 400ms linear 400ms forwards;
  }
}

// intro
.landing-intro {
  font-size: var(--font-size-2);
  text-align: center;
  position: relative;
  padding: 0 16px 5px;
  @media only screen and (min-width: 768px) {
    padding: 0 16px 20px;
  }
  &__title {
    font-size: 0.73em;
    line-height: 1.2;
    margin: 0 auto 48px;
    @media only screen and (min-width: 768px) {
      font-size: 0.96em;
      max-width: 60%;
      
    }
    strong {
      display: inline-block;
      line-height: 1;
    }
  }
  &__illustration {
    max-height: 60vh;
    margin: 0 auto;
    border-radius: 5%;
  }
}

// secure
.landing-secure-block-wrap {
  position: relative;
  padding-bottom: 24px;
}

.landing-secure-block {
  max-width: 1024px;
  margin: 32px auto;
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 768px) {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  &__item {
    @media only screen and (max-width: 767px) {
      max-width: 300px;
      margin: auto;
    }
    text-align: center;
    img {
      margin: 24px auto;
      max-width: 90%;
    }
  }
}

// next
.landing-whats-next {
  $this: &;
  max-width: 800px;
  margin: 48px auto 24px;
  padding: 0 32px;
  display: grid;
  row-gap: 100px;
  grid-template-columns: 1fr;
  color: var(--color-primary);

  @media only screen and (min-width: 768px) {
    row-gap: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  }

  &__item {
    position: relative;
    &:last-child {
      @media only screen and (min-width: 768px) {
        grid-column-start: 2;
        grid-row-start: 2;
      }
    }
    #{$this}__figure {
      margin: auto;
    }
  }

  &__title {
    text-align: center;
    width: 240px;
    display: inline-block;
    background-color: var(--color-primary);
    color: var(--color-white);
    font-weight: bold;
    padding: 8px;
    border-radius: 4px;
    position: absolute;
    top: -32px;
    right: 0;
  }

  &__figure {
    max-width: 300px;
  }
}

.fancy-border-radius-left,
.fancy-border-radius-right {
  --velocity: 500ms;
  margin: auto;
  width: 250px;
  height: 250px;
  transform: scale(0.1);
  background-color: var(--color-white);
  @media only screen and (min-width: 1024px) {
    width: 400px;
    height: 350px;
  }
}

.fancy-border-radius-left.view-in {
  animation: scaleAnimation var(--velocity) forwards;
}
.fancy-border-radius-right.view-in {
  animation: scaleAnimation var(--velocity) 100ms forwards;
}

.fancy-border-radius-left {
  border-radius: 74% 26% 59% 41% / 56% 27% 73% 44%;
}

.fancy-border-radius-right {
  border-radius: 25% 75% 24% 76% / 25% 25% 75% 75%;
}

.word-pulse {
  position: relative;
  display: inline-block;
  background-image: linear-gradient(
    90deg,
    var(--color-primary) 0%,
    var(--color-primary) 100%
  );
  background-size: cover;
  background-position: -1000px 0;
  &.view-in--full {
    animation: underlineWord 600ms linear 200ms forwards;
  }
}

// how
.landing-how {
  margin-top: 24px;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 64px;
  padding-left: 0;
  @media only screen and (min-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }
  li {
    min-height: 1px;
    counter-increment: section;
    position: relative;
    padding-left: 32px;
    max-width: 360px;
    margin: auto;
    @media only screen and (min-width: 768px) {
      max-width: initial;
    }
    &:before {
      --velocity: 300ms;
      --delay: 200ms;
      content: counter(section);
      position: absolute;
      z-index: 0;
      top: 0;
      line-height: 0.8;
      font-size: 90px;
      font-weight: bold;
      color: var(--color-primary);
      font-family: sans-serif;
      left: -4px;
      text-align: center;
      transform: scale(0);
      transform-origin: center center;

      @media only screen and (min-width: 768px) {
        font-size: 120px;
        line-height: 1;
      }
    }
  }
  // @at-root .landing-flat-animated {
  .landing-how__item:first-child.view-in:before {
    // prettier-ignore
    animation: scaleAnimation var(--velocity) calc(var(--delay) * 1) forwards;
  }
  .landing-how__item:nth-child(2).view-in:before {
    // prettier-ignore
    animation: scaleAnimation var(--velocity) calc(var(--delay) * 2) forwards;
  }
  .landing-how__item:nth-child(3).view-in:before {
    // prettier-ignore
    animation: scaleAnimation var(--velocity) calc(var(--delay) * 3) forwards;
  }
  .landing-how__item:last-child.view-in:before {
    // prettier-ignore
    animation: scaleAnimation var(--velocity) calc(var(--delay) * 4) forwards;
  }
  // }
  &__title {
    position: relative;
    z-index: 1;
    font-size: 32px;
    line-height: 1;
    max-width: 350px;
    @media only screen and (min-width: 768px) {
      font-size: 45px;
    }
  }
  &__item:nth-child(2n) {
    img {
      order: 1;
    }
  }

  &__figure {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    max-width: 240px;
    @media only screen and (min-width: 768px) {
      font-size: 24px;
      max-width: initial;
    }
    @media only screen and (min-width: 768px) {
      height: 200px;
      flex-wrap: nowrap;
    }
    > * {
      @media only screen and (min-width: 768px) {
        flex-basis: 50%;
        width: 50%;
      }
    }
    img {
      margin-right: 24px;
      margin: auto;
      object-fit: contain;
      margin: 16px;
    }
    p {
      margin-top: 8px;
    }
  }
}

@keyframes scaleAnimation {
  80% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

@keyframes underlineWord {
  to {
    background-position: 0 0;
  }
}

@keyframes opacityAnimation {
  to {
    opacity: 1;
  }
}

// feature-works
.landing-works {
  padding: 0 16px 24px;
  position: relative;
}
.landing-feature-works {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
  li {
    margin-bottom: 32px;
    @media only screen and (min-width: 768px) {
      margin: 0 16px;
    }
    &:last-child {
      display: none;
      @media only screen and (min-width: 768px) {
        display: block;
      }
    }

    img {
      border-radius: 4px;
      width: 66vw;
      margin: auto;
      box-shadow: 0 16px 16px -1px rgba(10, 22, 70, 0.1),
        0 0 1px 0 rgba(10, 22, 70, 0.06);
      z-index: 1;
      @media only screen and (min-width: 480px) {
        width: 40vw;
      }
      @media only screen and (min-width: 768px) {
        width: 25vw;
      }
      @media only screen and (min-width: 1200px) {
        width: 15vw;
      }
    }
  }
}
</style>
